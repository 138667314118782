import React from "react";
import ArtistCard from "../components/ArtistCard/ArtistCard";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import Art from "../assets/images/blank-profile-male.jpg";
import Wrapper from "../components/common/Wrapper";
import Span from "../components/common/Span";
import Content from "../components/Content/Content";

const Artist = () => {
  const location = useLocation(); // Get the current location from 'react-router-dom'

  const pathnames = location.pathname.split("/").filter((x) => x); // Split the pathname and remove empty elements
  const newPath = pathnames[pathnames.length - 1];
  return (
    <Content>
      <Wrapper gap="2rem" direction="column">
        <Wrapper gap="1rem" justifyContent="flex-end" alignItem="flex-end">
          <Span color="#a2a2a2">{newPath}</Span>
          <Img src={Art} />
        </Wrapper>
        <Wrapper>
          <Span color="#a2a2a2" textAlign="justify">
            {newPath} ipsum dolor sit amet consectetur adipisicing elit.
            Molestias voluptatum tempora ut fugit autem sed beatae eum tempore
            odio, quibusdam quidem repudiandae nemo modi dolorum libero corrupti
            voluptate facere quisquam? {newPath} , ipsum dolor sit amet
            consectetur adipisicing elit. Doloribus asperiores aliquam
            recusandae earum reprehenderit. Sapiente amet recusandae obcaecati
            delectus excepturi voluptatem distinctio sint quaerat voluptas iure,
            pariatur quibusdam, ipsa cumque. {newPath} ipsum dolor sit amet
            consectetur, adipisicing elit. Error recusandae officia odit animi
            commodi eligendi sit blanditiis soluta minus? Enim assumenda modi
            temporibus harum vitae accusantium sint quisquam, praesentium
            nostrum? {newPath} ipsum dolor sit amet consectetur adipisicing
            elit. Voluptatem laborum ab fugit exercitationem labore sed nemo
            vitae sequi, eum nobis eligendi, sunt aperiam eos quia
            necessitatibus totam obcaecati nostrum quis. {newPath}
            ipsum dolor sit amet consectetur adipisicing elit. Molestias
            voluptatum tempora ut fugit autem sed beatae eum tempore odio,
            quibusdam quidem repudiandae nemo modi dolorum libero corrupti
            voluptate facere quisquam? {newPath} , ipsum dolor sit amet
            consectetur adipisicing elit. Doloribus asperiores aliquam
            recusandae earum reprehenderit. Sapiente amet recusandae obcaecati
            delectus excepturi voluptatem distinctio sint quaerat voluptas iure,
            pariatur quibusdam, ipsa cumque. {newPath} ipsum dolor sit amet
            consectetur, adipisicing elit. Error recusandae officia odit animi
            commodi eligendi sit blanditiis soluta minus? Enim assumenda modi
            temporibus harum vitae accusantium sint quisquam, praesentium
            nostrum? {newPath} ipsum dolor sit amet consectetur adipisicing
            elit. Voluptatem laborum ab fugit exercitationem labore sed nemo
            vitae sequi, eum nobis eligendi, sunt aperiam eos quia
            necessitatibus totam obcaecati nostrum quis.
          </Span>
        </Wrapper>
      </Wrapper>
    </Content>
  );
};

export default Artist;
const Img = styled.img`
  height: 10rem;
  width: 10rem;
`;
