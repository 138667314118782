import React from "react";
import styled from "styled-components";
import BackgroundImage from "../assets/images/lightBackground.png";
import NotfoundImgOne from "../assets/images/404-page-not-found0.png";
import NotfoundImgTwo from "../assets/images/_e8377f1a-4b4c-439c-89e5-c708474344a3.png";
import BackgroundImage_two from "../assets/images/loginBackground.jpg";

import Wrapper from "../components/common/Wrapper";
import NotfoundImage from "../assets/images/404-page-not-found.svg";
import Typography from "../components/common/Typography";
import { Link, useNavigate } from "react-router-dom";
import Span from "../components/common/Span";
const NotFoundPage = () => {
  const navigate = useNavigate();
  const BackHandler = () => {
    navigate("./");
  };
  return (
    <Main>
      <Modal>
        <Wrapper
          direction="column"
          rdirection="column"
          rjustifyContent="flex-start"
          justifyContent="flex-start"
          height="100vh"
        >
          <Typography color="#ec3323" weight="bold" size="1.8rem" rsize="1.5rem">
            Not Found{" "}
          </Typography>
          <Wrapper width="20%">
            {/* <StyledLink to="./">Back</StyledLink> */}
            <CustomButton className="type1" onClick={() => BackHandler()}>
              <BorderContainer>
                <Span>BACK TO HOME</Span>
              </BorderContainer>
            </CustomButton>
          </Wrapper>
        </Wrapper>
      </Modal>
    </Main>
  );
};

export default NotFoundPage;

// Styling for the image
const Img = styled.img`
  width: 50%;
  height: 70%;
  transition: all 0.6s ease;

  &:hover{
    width: 52%;
    height: 71%;
  }

  @media screen and (max-width: 1023px) {
    width: 80%;
    height: 60%;
  }
`;

// Styling for the modal container
const Modal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(9px);
  /* overflow: auto; */
  /* background: linear-gradient(
    107.84deg,
    rgba(255, 255, 255, 0.44) 0%,
    rgba(255, 255, 255, 0.0836) 100%
  ); */

  @media screen and (max-width: 1023px) {
    // padding: 0.8rem;
  }
`;

// Styling for the link
const StyledLink = styled(Link)`
  transition: all 0.5s ease;
  color: #000;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  &:hover {
    color: #235789;
    font-weight: bolder;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  width: 100%;
  /* height: 100%; */

  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : "none"};

  @media screen and (max-width: 1023px) {
    /* height: 100vh; */
    /* background-color:red; */
    /* background-image:none; */
    /* background-attachment:scroll; */
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: ${({ rdirection }) => (rdirection ? rdirection : "row")};
    /* flex-direction:row; */
  }
`;

const color1 = "#ec3323"; // Replace with your color value

export const CustomButton = styled.button`
  width: 100%;
  position: relative;
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &:hover {
    div {
      background-color: #ec3323;
      span {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  &.type1 {
    color: ${color1};

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 5%;
      height: 20%;
      border: 2px solid;
      transition: all 0.6s ease;
      border-radius: 2px;
    }

    &::after {
      bottom: 0;
      right: 0;
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: ${color1};
      border-right-color: ${color1};
    }

    &::before {
      top: 0;
      left: 0;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-top-color: ${color1};
      border-left-color: ${color1};
    }

    &:hover::after,
    &:hover::before {
      width: 99%;
      height: 95%;
    }
  }
`;

const BorderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #ec3323;
  transition: border 0.6s ease-in-out;
`;
