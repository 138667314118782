import React from "react";
import Wrapper from "../common/Wrapper";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../common/Button";
import SideBar from "./SideBar";

// Define a functional component called 'Layout' that receives a 'children' prop
const Layout = ({ children }) => {


  return (
    <Wrapper
      height="100vh"
      justifyContent="space-between"
      rheight="100vh"
      rjustifyContent="space-between"
    >
 <SideBar/>
      <Wrapper >{children}</Wrapper>
    </Wrapper>
  );
};
// Export the 'Layout' component as the default export of this module
export default Layout;
