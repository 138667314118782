const initialState = {
    status: "idle",

  };
  
  export const redirectToEnReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_REDIRECT_TO_EN_LOADING":
        return {
          ...state,
          status: "pending",
        };
      case "FETCH_REDIRECT_TO_EN_DONE":
        return {
          ...state,
          status: "success",
 
        };
      case "FETCH_REDIRECT_TO_EN_FAILED":
        return {
          ...state,
          status: "failed",
        };
      default:
        return state;
    }
  };
  