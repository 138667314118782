import React, { useState } from "react";
import Wrapper from "../components/common/Wrapper";
import Span from "../components/common/Span";
import Content from "../components/Content/Content";
import { Icon } from "./Links";



const Home = () => {

  return (
    <Content>

   <Wrapper>
   <Wrapper justifyContent="flex-start" padding="0 0 2rem 0">
          <Icon />
          <Wrapper justifyContent="flex-start" gap="0.5rem">

          <Span size="1.5rem" weight="bold" color="#8d8d8d">04 </Span>
          <Span size="1.5rem" weight="bold" color="#8d8d8d">Art Gallery</Span>
          </Wrapper>
        </Wrapper>
   </Wrapper>
    </Content>
  );
};

export default Home;
