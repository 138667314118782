import React from "react";
import Content from "../components/Content/Content";
import styled from "styled-components";
import { RiCheckboxBlankFill } from "react-icons/ri";
import Wrapper from "../components/common/Wrapper";
import Span from "../components/common/Span";

const Links = () => {
  return (
    <Content>
      <Wrapper justifyContent="flex-start" padding="0 0 2rem 0">
        <Icon />
        <Span size="1.5rem" weight="bold" color="#8d8d8d">
          04{" "}
        </Span>
        <Span size="1.5rem" weight="bold" color="#8d8d8d">
          Art Gallery
        </Span>
      </Wrapper>
      <Wrapper direction="column" gap="2rem">
      <Wrapper justifyContent="flex-start">
          <Icon />
          <Span color="#a2a2a2">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit
          </Span>
        </Wrapper>
        <Wrapper justifyContent="flex-start">
          <Icon />
          <Span color="#a2a2a2">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit
          </Span>
        </Wrapper>
        <Wrapper justifyContent="flex-start">
          <Icon />
          <Span color="#a2a2a2">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit
          </Span>
        </Wrapper>
      </Wrapper>
    </Content>
  );
};

export default Links;

export const Icon = styled(RiCheckboxBlankFill)`
  font-size: 1.5rem;
  color: #a2a2a2;
  cursor: pointer;
`;
