import React from 'react'
import Content from '../components/Content/Content'

const Search = () => {
  return (
    <Content >

    <div>Search</div>
    </Content>
  )
}

export default Search 