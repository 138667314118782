const initialState = {
  status: "idle",
  listArray: {},
};

export const getBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_GET_BALANCE_LOADING":
      return {
        ...state,
        status: "pending",
      };

    case "FETCH_GET_BALANCE_DONE":
      return {
        ...state,
        status: "success",
        listArray: action.payload,
      };
    case "FETCH_GET_BALANCE_FAILED":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state; 
  }
};
