const initialState = {
    status: "idle",
  };
  
  export const reserveCarReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_CAR_RESERVE_LOADING":
        return {
          ...state,
          status: "pending",
        };
      case "FETCH_CAR_RESERVE_DONE":
        return {
          ...state,
          status: "success",
        };
      case "FETCH_CAR_RESERVE_FAILED":
        return {
          ...state,
          status: "failed",
        };
      default:
        return state;
    }
  };
  