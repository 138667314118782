const initialState = {
    status: "idle",
    listArray :[]
  };
  
  export const sendBillNumberReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_SEND_BILLNUMBER_LOADING":
        return {
          ...state,
          status: "pending",
        };
      case "FETCH_SEND_BILLNUMBER_DONE":
        return {
          ...state,
          status: "success",
          listArray: action.payload,
        };
      case "FETCH_SEND_BILLNUMBER_FAILED":
        return {
          ...state,
          status: "failed",
        };
      default:
        return state;
    }
  };
  