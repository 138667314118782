const initialState = {
  status: "idle",
  locationList: [],
};

export const getLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LOCATION_LOADING":
      return {
        ...state,
        status: "pending",
      };
    case "GET_LOCATION_DONE":
      return {
        ...state,
        status: "success",
        locationList: action.payload,
      };
    case "GET_LOCATION_FAILED":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state;
  }
};
