import React from "react";
import Content from "../components/Content/Content";
import Artist from "./Artist";
import ArtistCard from "../components/ArtistCard/ArtistCard";
import { useNavigate } from "react-router";
import Wrapper from "../components/common/Wrapper";

const Artists = () => {
  const Test = {
    text: "Artists, ipsum dolor sit amet consectetur adipisicing elit. Optio dolorArtists, ipsum dolor sit amet consectetur adipisicing elit. Optio dolorArtists, ipsum dolor sit amet consectetur adipisicing elit. Optio dolorArtists, ipsum dolor sit amet consectetur adipisicing elit. Optio dolor",
  };

  const ArtistList = [
    { name: "num01", address: "num01" },
    { name: "num02", address: "num02" },
    { name: "num03", address: "num03" },
    { name: "num04", address: "num04" },
    { name: "num05", address: "num05" },
    { name: "num06", address: "num06" },
    { name: "num07", address: "num07" },
    { name: "num08", address: "num08" },
    { name: "num09", address: "num09" },
    { name: "num10", address: "num10" },
    { name: "num11", address: "num11" },
    { name: "num12", address: "num12" },
    { name: "num13", address: "num13" },
    { name: "num14", address: "num14" },
    { name: "num15", address: "num15" },
    { name: "num16", address: "num16" },
    { name: "num17", address: "num17" },
    { name: "num18", address: "num18" },
    { name: "num19", address: "num19" },
    { name: "num20", address: "num20" },
    { name: "num21", address: "num21" },
    { name: "num22", address: "num22" },
    { name: "num23", address: "num23" },
    { name: "num24", address: "num24" },
    { name: "num25", address: "num25" },
    { name: "num26", address: "num26" },
    { name: "num27", address: "num27" },
    { name: "num28", address: "num28" },
    { name: "num29", address: "num29" },
    { name: "num30", address: "num30" },
    { name: "num31", address: "num31" },
    { name: "num32", address: "num32" },
    { name: "num33", address: "num33" },
    { name: "num34", address: "num34" },
    { name: "num35", address: "num35" },
    { name: "num36", address: "num36" },
    { name: "num37", address: "num37" },
    { name: "num38", address: "num38" },
    { name: "num39", address: "num39" },
    { name: "num40", address: "num40" },
    { name: "num41", address: "num41" },
    { name: "num42", address: "num42" },
    { name: "num43", address: "num43" },
    { name: "num44", address: "num44" },
    { name: "num45", address: "num45" },
    { name: "num46", address: "num46" },
    { name: "num47", address: "num47" },
    { name: "num48", address: "num48" },
    { name: "num49", address: "num49" },
    { name: "num50", address: "num50" },
  ];
  const navigate = useNavigate();
  const onClickHandler = (add) => {
    navigate(`/artists/${add}`);
  };
  return (
    <>
      <Content>
        {/* <Artist/> */}

        <Wrapper wrap="wrap" gap="2rem" justifyContent="flex-start">
          {ArtistList.map((item) => (
            <Wrapper
              cursor="pointer"
              width="30%"
              onClick={() => onClickHandler(item.address)}
            >
              <ArtistCard name={item.name} address={item.address} />
            </Wrapper>
          ))}
        </Wrapper>
      </Content>
    </>
  );
};

export default Artists;
