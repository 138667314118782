const initialState = {
  status: "idle",
  notification :[]
};

export const sendTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SEND_TOKEN_LOADING":
      return {
        ...state,
        status: "pending",
      };

    case "FETCH_SEND_TOKEN_DONE":
      return {
        ...state,
        status: "success",
        notification: action.payload,

      };
    case "FETCH_SEND_TOKEN_FAILED":
      return {
        ...state,
        status: "failed",
      };
      default:
        return state;
  }
};
