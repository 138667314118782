const initialState = {
  status: "idle",
  listArray: [],
};

export const getReserveListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_RESERVE_LIST_LOADING":
      return {
        ...state,
        status :"pending"
      };
    case "GET_RESERVE_LIST_DONE":
      return {
        ...state,
        status : "success",
        listArray : action.payload
      };
    case "GET_RESERVE_LIST_FAILED":
      return {
        ...state,
        status : "failed" 
      };
    default:
      return state;
  }
};
