import React from "react";
import styled from "styled-components";

// Define a Wrapper component that accepts various styling and responsive props
const Wrapper = ({
  children,
  onClick,
  display,
  Zindex,
  id,
  key,
  justifySelf,
  alignSelf,
  borderRadius,
  boxShadow,
  borderTop,
  borderLeft,
  borderRight,
  height,
  direction,
  width,
  gap,
  padding,
  margin,
  background,
  alignItem,
  justifyContent,
  textDirection,
  position,
  top,
  left,
  bottom,
  backgroundLinear,
  backdropFilter,
  right,
  overflowY,
  overflowX,
  wrap,
  border,
  borderBottom,
  msheight,
  msdirection,
  mswidth,
  msgap,
  mspadding,
  msmargin,
  msbackground,
  msalignItem,
  msjustifyContent,
  mstextDirection,
  rdisplay,
  mlheight,
  mldirection,
  mlwidth,
  mlgap,
  mlpadding,
  mlmargin,
  mlbackground,
  mlalignItem,
  mljustifyContent,
  mltextDirection,

  mxheight,
  mxdirection,
  mxwidth,
  mxgap,
  mxpadding,
  mxmargin,
  mxbackground,
  mxalignItem,
  mxjustifyContent,
  mxtextDirection,

  theight,
  tdirection,
  twidth,
  tgap,
  tpadding,
  tmargin,
  tbackground,
  talignItem,
  tjustifyContent,
  ttextDirection,

  rdirection,
  ralignItem,
  rwidth,
  rjustifyContent,
  rbackground,
  rheight,
  rpadding,
  rmargin,
  rgap,
  rborderRadius,
  ralignContennt,
  rtop,
  rbottom,
  rright,
  rleft,
  rposition,
  rZindex,
  Rborder,
  cursor,
  transition,
  transform,
  onScroll,
  overflow,
  backgroundImage,
}) => {
  return (
    <Container
      Zindex={Zindex}
      borderTop={borderTop}
      backdropFilter={backdropFilter}
      borderLeft={borderLeft}
      borderRight={borderRight}
      display={display}
      onClick={onClick}
      onScroll={onScroll}
      id={id}
      key={key}
      justifySelf={justifySelf}
      alignSelf={alignSelf}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      height={height}
      direction={direction}
      width={width}
      gap={gap}
      padding={padding}
      margin={margin}
      background={background}
      backgroundLinear={backgroundLinear}
      alignItem={alignItem}
      justifyContent={justifyContent}
      textDirection={textDirection}
      position={position}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      overflowY={overflowY}
      overflowX={overflowX}
      wrap={wrap}
      border={border}
      borderBottom={borderBottom}
      msheight={msheight}
      msdirection={msdirection}
      mswidth={mswidth}
      msgap={msgap}
      mspadding={mspadding}
      msmargin={msmargin}
      msbackground={msbackground}
      msalignItem={msalignItem}
      msjustifyContent={msjustifyContent}
      mstextDirection={mstextDirection}
      mlheight={mlheight}
      mldirection={mldirection}
      mlwidth={mlwidth}
      mlgap={mlgap}
      mlpadding={mlpadding}
      mlmargin={mlmargin}
      mlbackground={mlbackground}
      mlalignItem={mlalignItem}
      mljustifyContent={mljustifyContent}
      mltextDirection={mltextDirection}
      mxheight={mxheight}
      mxdirection={mxdirection}
      mxwidth={mxwidth}
      mxgap={mxgap}
      mxpadding={mxpadding}
      mxmargin={mxmargin}
      mxbackground={mxbackground}
      mxalignItem={mxalignItem}
      mxjustifyContent={mxjustifyContent}
      mxtextDirection={mxtextDirection}
      theight={theight}
      tdirection={tdirection}
      twidth={twidth}
      tgap={tgap}
      tpadding={tpadding}
      tmargin={tmargin}
      tbackground={tbackground}
      talignItem={talignItem}
      tjustifyContent={tjustifyContent}
      ttextDirection={ttextDirection}
      rdirection={rdirection}
      ralignItem={ralignItem}
      rjustifyContent={rjustifyContent}
      rwidth={rwidth}
      rbackground={rbackground}
      rheight={rheight}
      rpadding={rpadding}
      rmargin={rmargin}
      rgap={rgap}
      rborderRadius={rborderRadius}
      ralignContennt={ralignContennt}
      rtop={rtop}
      rbottom={rbottom}
      rleft={rleft}
      rright={rright}
      rdisplay={rdisplay}
      rposition={rposition}
      rZindex={rZindex}
      Rborder={Rborder}
      cursor={cursor}
      transition={transition}
      transform={transform}
      overflow={overflow}
      backgroundImage={backgroundImage}
    >
      {children}
    </Container>
  );
};
export default Wrapper;// Export the Wrapper component

// Define a styled div with various CSS properties and responsive styles
export const Container = styled.div`
  /* display: flex; */
  justify-self: ${({ justifySelf }) => (justifySelf ? justifySelf : null)};
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : null)};
  display: ${({ display }) => (display ? display : "flex")};
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItem }) => (alignItem ? alignItem : "center")};
  background-color: ${({ background }) => (background ? background : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  gap: ${({ gap }) => (gap ? gap : null)};
  z-index: ${({ Zindex }) => (Zindex ? Zindex : null)};
  width: ${({ width }) => (width ? width : "100%")};
  direction: ${({ textDirection }) => (textDirection ? textDirection : null)};
  height: ${({ height }) => (height ? height : null)};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : null)};
  position: ${({ position }) => (position ? position : null)};
  top: ${({ top }) => (top ? top : null)};
  left: ${({ left }) => (left ? left : null)};
  bottom: ${({ bottom }) => (bottom ? bottom : null)};
  right: ${({ right }) => (right ? right : null)};
  overflow-y: ${({ overflowY }) => (overflowY ? overflowY : null)};
  overflow-x: ${({ overflowX }) => (overflowX ? overflowX : null)};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : null)};
  border: ${({ border }) => (border ? border : null)};
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : null)};
  border-top: ${({ borderTop }) => (borderTop ? borderTop : null)};
  border-left: ${({ borderLeft }) => (borderLeft ? borderLeft : null)};
  border-right: ${({ borderRight }) => (borderRight ? borderRight : null)};
  background: ${({ backgroundLinear }) =>
    backgroundLinear ? backgroundLinear : null};
  backdrop-filter: ${({ backdropFilter }) =>
    backdropFilter ? backdropFilter : null};
  cursor: ${({ cursor }) => (cursor ? cursor : null)};
  overflow: ${({ overflow }) => (overflow ? overflow : null)};
  transition: ${({ transition }) => (transition ? transition : null)};
  transform: ${({ transform }) => (transform ? transform : null)};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage}) ` : null};
  background-repeat: no-repeat;
  background-size: cover;
  //Responsive
  ::-webkit-scrollbar {
    display: show;
  }
  //ms
  @media screen and (max-width: 319px) {
    flex-direction: ${({ msdirection }) => (msdirection ? msdirection : "row")};
    justify-content: ${({ msjustifyContent }) =>
      msjustifyContent ? msjustifyContent : "center"};
    align-items: ${({ msalignItem }) => (msalignItem ? msalignItem : "center")};
    background-color: ${({ msbackground }) =>
      msbackground ? msbackground : null};
    margin: ${({ msmargin }) => (msmargin ? msmargin : null)};
    padding: ${({ mspadding }) => (mspadding ? mspadding : null)};
    gap: ${({ msgap }) => (msgap ? msgap : null)};
    width: ${({ mswidth }) => (mswidth ? mswidth : "100%")};
    direction: ${({ mstextDirection }) =>
      mstextDirection ? mstextDirection : "null"};
    height: ${({ msheight }) => (msheight ? msheight : null)};
  }

  //ml
  @media screen and (min-width: 320px) and (max-width: 479px) {
    flex-direction: ${({ mldirection }) => (mldirection ? mldirection : "row")};
    justify-content: ${({ mljustifyContent }) =>
      mljustifyContent ? mljustifyContent : "center"};
    align-items: ${({ mlalignItem }) => (mlalignItem ? mlalignItem : "center")};
    background-color: ${({ mlbackground }) =>
      mlbackground ? mlbackground : null};
    margin: ${({ margin }) => (margin ? margin : null)};
    padding: ${({ mlpadding }) => (mlpadding ? mlpadding : null)};
    gap: ${({ gap }) => (gap ? gap : null)};
    width: ${({ mlwidth }) => (mlwidth ? mlwidth : "100%")};
    direction: ${({ mltextDirection }) =>
      mltextDirection ? mltextDirection : "null"};
    height: ${({ mlheight }) => (mlheight ? mlheight : null)};
  }

  //mx
  @media screen and (min-width: 480px) and (max-width: 767px) {
    flex-direction: ${({ mxdirection }) => (mxdirection ? mxdirection : "row")};
    justify-content: ${({ mxjustifyContent }) =>
      mxjustifyContent ? mxjustifyContent : "center"};
    align-items: ${({ mxalignItem }) => (mxalignItem ? mxalignItem : "center")};
    background-color: ${({ mxbackground }) =>
      mxbackground ? mxbackground : null};
    margin: ${({ margin }) => (margin ? margin : null)};
    padding: ${({ mxpadding }) => (mxpadding ? mxpadding : null)};
    gap: ${({ mxgap }) => (mxgap ? mxgap : null)};
    width: ${({ mxwidth }) => (mxwidth ? mxwidth : "100%")};
    direction: ${({ mxtextDirection }) =>
      mxtextDirection ? mxtextDirection : "null"};
    height: ${({ mxheight }) => (mxheight ? mxheight : null)};
  }

  //t
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex-direction: ${({ tdirection }) => (tdirection ? tdirection : "row")};
    justify-content: ${({ tjustifyContent }) =>
      tjustifyContent ? tjustifyContent : "center"};
    align-items: ${({ talignItem }) => (talignItem ? talignItem : "center")};
    background-color: ${({ tbackground }) =>
      tbackground ? tbackground : null};
    margin: ${({ tmargin }) => (tmargin ? tmargin : null)};
    padding: ${({ tpadding }) => (tpadding ? tpadding : null)};
    gap: ${({ tgap }) => (tgap ? tgap : null)};
    width: ${({ twidth }) => (twidth ? twidth : "100%")};
    direction: ${({ ttextDirection }) =>
      ttextDirection ? ttextDirection : "null"};
    height: ${({ theight }) => (theight ? theight : null)};
  }

  //r
  @media screen and (max-width: 1023px) {
    display: ${({ rdisplay }) => (rdisplay ? rdisplay : "flex")};
    flex-direction: ${({ rdirection }) => (rdirection ? rdirection : "row")};
    align-items: ${({ ralignItem }) => (ralignItem ? ralignItem : "center")};
    justify-content: ${({ rjustifyContent }) =>
      rjustifyContent ? rjustifyContent : "center"};
    width: ${({ rwidth }) => (rwidth ? rwidth : "100%")};
    background-color: ${({ rbackground }) =>
      rbackground ? rbackground : null};
    height: ${({ rheight }) => (rheight ? rheight : null)};
    padding: ${({ rpadding }) => (rpadding ? rpadding : null)};
    margin: ${({ rmargin }) => (rmargin ? rmargin : null)};
    gap: ${({ rgap }) => (rgap ? rgap : null)};
    border-radius: ${({ rborderRadius }) =>
      rborderRadius ? rborderRadius : null};
    align-content: ${({ ralignContennt }) =>
      ralignContennt ? ralignContennt : null};
    position: ${({ rposition }) => (rposition ? rposition : null)};
    top: ${({ rtop }) => (rtop ? rtop : null)};
    left: ${({ rleft }) => (rleft ? rleft : null)};
    bottom: ${({ rbottom }) => (rbottom ? rbottom : null)};
    right: ${({ rright }) => (rright ? rright : null)};
    z-index: ${({ rZindex }) => (rZindex ? rZindex : null)};
    border: ${({ Rborder }) => (Rborder ? Rborder : null)};
  }
`;
