import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Wrapper from "../common/Wrapper";
import { RiCheckboxBlankFill } from "react-icons/ri";
import Typography from "../common/Typography";

const SideBar = () => {
  const location = useLocation(); // Get the current location from 'react-router-dom'

  const pathnames = location.pathname.split("/").filter((x) => x); // Split the pathname and remove empty elements
  const newPath = pathnames[pathnames.length - 1];
const navigate = useNavigate()
  return (
    <Wrapper alignItem="flex-end"  height="100vh">
        
      <Wrapper  gap="1rem" direction="column" padding="8rem">
        <Wrapper justifyContent="flex-start" >
        <Icon isActive={ newPath === undefined } cursor="pointer" onClick={() => navigate("/")}/>
        <Typography cursor="pointer" onClick={() => navigate("/")}>04 </Typography>
        <Typography cursor="pointer" onClick={() => navigate("/")}>Art Gallery </Typography>
        </Wrapper>
        <Wrapper justifyContent="flex-start">
          <Icon isActive={ newPath === "about-us"} />
          <StyledLink to="/about-us">About us</StyledLink>
        </Wrapper>{" "}
        <Wrapper justifyContent="flex-start">
          <Icon isActive={newPath ==="gallery"} />
          <StyledLink to="/gallery">Gallery</StyledLink>
        </Wrapper>{" "}
        <Wrapper justifyContent="flex-start">
          <Icon isActive={newPath === "artists"} />
          <StyledLink to="/artists">Artists</StyledLink>
        </Wrapper>{" "}
        <Wrapper justifyContent="flex-start">
          <Icon isActive={newPath === "news"} />
          <StyledLink to="/news">News</StyledLink>
        </Wrapper>{" "}
        <Wrapper justifyContent="flex-start">
          <Icon isActive={newPath === "links"} />
          <StyledLink to="/links">Links</StyledLink>
        </Wrapper>{" "}
        <Wrapper justifyContent="flex-start">
          <Icon isActive={newPath === "contact"} />
          <StyledLink to="/contact">Contact</StyledLink>
        </Wrapper>{" "}
        <Wrapper justifyContent="flex-start">
          <Icon isActive={newPath === "store"} />
          <StyledLink to="/store">Store</StyledLink>
        </Wrapper>{" "}
        <Wrapper justifyContent="flex-start">
          <Icon isActive={newPath === "search"} />
          <StyledLink to="/search">Search</StyledLink>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default SideBar;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
  padding-left: 0.2rem;
`;



const Icon = styled(RiCheckboxBlankFill)`
font-size: 1.5rem;
color: ${({ isActive }) => (isActive ? "#616161" : "#989898")};
cursor: pointer;
`;
