
import React from 'react'
import Wrapper from '../common/Wrapper'
import styled from 'styled-components';

export const GalleryCard = ({src}) => {
  return (
    <Wrapper>
        <Img src={src}/>
    </Wrapper>
  )
}


const Img = styled.img`
  height: 40rem;
  width: 100%;
`;