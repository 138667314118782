import React from "react";
import Wrapper from "../common/Wrapper";
import Art from "../../assets/images/blank-profile-male.jpg";
import styled from "styled-components";
import Span from "../common/Span";
const ArtistCard = ({ name, address }) => {
  return (
    <Wrapper border="1px solid" direction="column">
      <Img src={Art} />
      <Wrapper justifyContent="flex-start" padding="0.2rem 0 0 0 ">
        <Span color="#a2a2a2">{name}</Span>
      </Wrapper>
    </Wrapper>
  );
};

export default ArtistCard;

const Img = styled.img`
  height: 10rem;
  width: 100%;
`;
