import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

import { Suspense, lazy } from "react";
import Wrapper from "./components/common/Wrapper";
import Typography from "./components/common/Typography";
import Layout from "./components/Layout/Layout";

import { ToastContainer } from "react-toastify";
import NotFoundPage from "./pages/NotFoundPage";

import Home from "./pages/Home";
import Store from "./pages/Store";
import Search from "./pages/Search";
import AboutUs from "./pages/AboutUs";
import Gallery from "./pages/Gallery";
import Artists from "./pages/Artists";
import News from "./pages/News";
import Links from "./pages/Links";
import Contact from "./pages/Contact";
import Artist from "./pages/Artist";
import { GalleryCard } from "./components/GalleryCard/GalleryCard";
import GalleryDetails from "./pages/GalleryDetails";

/*
  Lazy-loaded components using React Suspense and lazy.
  Lazy loading optimizes the initial loading performance by loading components only when needed.
*/

/*
  LoadingFallback Component:
  A component displayed during lazy loading, indicating that the user is being redirected to a specific page.
*/
const LoadingFallback = () => (
  <Wrapper height="100vh" ralignItem="flex-start" alignItem="flex-start">
    <Typography color="#112D4E" size="1.5rem" weight="bold" rsize="0.8rem">
      Loading . . .{" "}
    </Typography>
  </Wrapper>
);

//loading

function App() {
  // const loading = useSelector((state) => state?.loading?.showLoading);
  // console.log("loading", loading);

  return (
    <div>
      <Router>
        {/*
          React Suspense is used to specify a fallback UI while waiting for lazy-loaded components to load.
        */}
        <Suspense fallback={<LoadingFallback />}>
          {/* Define the application routes */}

          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/store"
              element={
                <Layout>
                  <Store />
                </Layout>
              }
            />
            <Route
              path="/search"
              element={
                <Layout>
                  <Search />
                </Layout>
              }
            />
            <Route
              path="/about-us"
              element={
                <Layout>
                  <AboutUs />
                </Layout>
              }
            />
            <Route
              path="/gallery"
              element={
                <Layout>
                  <Gallery />
                </Layout>
              }
            />{" "}
            <Route
              path="/gallery/:id" 
              element={
                <Layout>
                  <GalleryDetails />
                </Layout>
              }
            /> 
            <Route
              path="/artists"
              element={
                <Layout>
                  <Artists />
                </Layout>
              }
            />{" "}
            <Route
              path="/artists/:id"
              element={
                <Layout>
                  <Artist />
                </Layout>
              }
            />
            <Route
              path="/news"
              element={
                <Layout>
                  <News />
                </Layout>
              }
            />
            <Route
              path="/links"
              element={
                <Layout>
                  <Links />
                </Layout>
              }
            />
            <Route
              path="/contact"
              element={
                <Layout>
                  <Contact />
                </Layout>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Router>
      {/* Display toast notifications */}
      <ToastContainer />
      {/* Display notifications if the user is authenticated */}{" "}
    </div>
  );
}

export default App;
