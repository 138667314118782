import React from "react";
import Wrapper from "../components/common/Wrapper";
import { useLocation } from "react-router";
import one from "../assets/images/audii.png";
import two from "../assets/images/benz.jpg";
import three from "../assets/images/blackCar-.jpg";
import four from "../assets/images/bmv520.png";
import five from "../assets/images/dodge.jpg";
import styled from "styled-components";
import Span from "../components/common/Span";
import { RiCheckboxBlankFill } from "react-icons/ri";
import Content from "../components/Content/Content";

const GalleryDetails = ({ name }) => {
  const srcList = [
    { src: five, name: "numb_05", since: 2022, numb: 7, city: "BJ" },
    { src: one, name: "numb_01", since: 2022, numb: 3, city: "NY" },
    { src: two, name: "numb_02", since: 2022, numb: 6, city: "WDC" },
    { src: three, name: "numb_03", since: 2022, numb: 12, city: "TH" },
    { src: four, name: "numb_04", since: 2022, numb: 5, city: "MSH" },
  ];
  const location = useLocation(); // Get the current location from 'react-router-dom'

  const pathnames = location.pathname.split("/").filter((x) => x); // Split the pathname and remove empty elements
  const newPath = pathnames[pathnames.length - 1];

  const pic = srcList.filter((item) => item.name === newPath);
  console.log("pic", pic);
  return (
    <Content>
      <Wrapper direction="column">
        <Wrapper justifyContent="space-between">
          <Wrapper direction="column">
            <Wrapper justifyContent="flex-start">
              <Icon />
              {pic[0].city}
            </Wrapper>
            <Wrapper justifyContent="flex-start">
              <Icon />
              {pic[0].name}
            </Wrapper>
            <Wrapper justifyContent="flex-start">
              <Icon />
              {pic[0].numb}
            </Wrapper>
            <Wrapper justifyContent="flex-start">
              <Icon />
              {pic[0].since}
            </Wrapper>
          </Wrapper>
          <Wrapper
            alignItem="flex-start"
            direction="column"
            width="70%"
            padding="1rem 0"
          >
            <Img src={pic[0].src} />
            <Wrapper justifyContent="flex-start">
              <Icon />
              <Span>{pic[0].name}</Span>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper>
          <Span textAlign="justify">
            {pic[0].name} ipsum dolor sit amet consectetur adipisicing elit.
            Minima do{pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam,
            officia minus officiis natus sint accusamus maxime distinctio culpa
            vitae nihil inventore!{pic[0].name} ipsum dolor sit amet consectetur
            adipisicing elit. Minima do{pic[0].name} totam ex eius cumque qui!
            Sunt quaerat ipsam, officia minus officiis natus sint accusamus
            maxime distinctio culpa vitae nihil inventore!{pic[0].name} ipsum
            dolor sit amet consectetur adipisicing elit. Minima do{pic[0].name}{" "}
            totam ex eius cumque qui! Sunt quaerat ipsam, officia minus officiis
            natus sint accusamus maxime distinctio culpa vitae nihil inventore!{" "}
            {pic[0].name} ipsum dolor sit amet consectetur adipisicing elit.
            Minima do
            {pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam, officia
            minus officiis natus sint accusamus maxime distinctio culpa vitae
            nihil inventore!{pic[0].name} ipsum dolor sit amet consectetur
            adipisicing elit. Minima do{pic[0].name} totam ex eius cumque qui!
            Sunt quaerat ipsam, officia minus officiis natus sint accusamus
            maxime distinctio culpa vitae nihil inventore!
            {pic[0].name} ipsum dolor sit amet consectetur adipisicing elit.
            Minima do{pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam,
            officia minus officiis natus sint accusamus maxime distinctio culpa
            vitae nihil inventore!      Minima do
            {pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam, officia
            minus officiis natus sint accusamus maxime distinctio culpa vitae
            nihil inventore!{pic[0].name} ipsum dolor sit amet consectetur
            adipisicing elit. Minima do{pic[0].name} totam ex eius cumque qui!
            Sunt quaerat ipsam, officia minus officiis natus sint accusamus
            maxime distinctio culpa vitae nihil inventore!
            {pic[0].name} ipsum dolor sit amet consectetur adipisicing elit.
            Minima do{pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam,
            officia minus officiis natus sint accusamus maxime distinctio culpa
            vitae nihil inventore!      {pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam, officia
            minus officiis natus sint accusamus maxime distinctio culpa vitae
            nihil inventore!{pic[0].name} ipsum dolor sit amet consectetur
            adipisicing elit. Minima do{pic[0].name} totam ex eius cumque qui!
            Sunt quaerat ipsam, officia minus officiis natus sint accusamus
            maxime distinctio culpa vitae nihil inventore!
            {pic[0].name} ipsum dolor sit amet consectetur adipisicing elit.
            Minima do{pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam,
            officia minus officiis natus sint accusamus maxime distinctio culpa
            vitae nihil inventore!      Minima do
            {pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam, officia
            minus officiis natus sint accusamus maxime distinctio culpa vitae
            nihil inventore!{pic[0].name} ipsum dolor sit amet consectetur
            adipisicing elit. Minima do{pic[0].name} totam ex eius cumque qui!
            Sunt quaerat ipsam, officia minus officiis natus sint accusamus
            maxime distinctio culpa vitae nihil inventore!
            {pic[0].name} ipsum dolor sit amet consectetur adipisicing elit.
            Minima do{pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam,
            officia minus officiis natus sint accusamus maxime distinctio culpa
            vitae nihil inventore!      {pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam, officia
            minus officiis natus sint accusamus maxime distinctio culpa vitae
            nihil inventore!{pic[0].name} ipsum dolor sit amet consectetur
            adipisicing elit. Minima do{pic[0].name} totam ex eius cumque qui!
            Sunt quaerat ipsam, officia minus officiis natus sint accusamus
            maxime distinctio culpa vitae nihil inventore!
            {pic[0].name} ipsum dolor sit amet consectetur adipisicing elit.
            Minima do{pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam,
            officia minus officiis natus sint accusamus maxime distinctio culpa
            vitae nihil inventore!      Minima do
            {pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam, officia
            minus officiis natus sint accusamus maxime distinctio culpa vitae
            nihil inventore!{pic[0].name} ipsum dolor sit amet consectetur
            adipisicing elit. Minima do{pic[0].name} totam ex eius cumque qui!
            Sunt quaerat ipsam, officia minus officiis natus sint accusamus
            maxime distinctio culpa vitae nihil inventore!
            {pic[0].name} ipsum dolor sit amet consectetur adipisicing elit.
            Minima do{pic[0].name} totam ex eius cumque qui! Sunt quaerat ipsam,
            officia minus officiis natus sint accusamus maxime distinctio culpa
            vitae nihil inventore!
          </Span>
        </Wrapper>
      </Wrapper>
    </Content>
  );
};

export default GalleryDetails;

const Img = styled.img`
  height:18rem;
  width: 100%;
  background-color: #c1c1c1;
`;

const Icon = styled(RiCheckboxBlankFill)`
  font-size: 1.5rem;
  color: gray;
  cursor: pointer;
`;
