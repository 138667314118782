const initialState = {
  status: "idle",
  carDetails: [],
};

export const getCarByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case "":
      return {
        ...state,
        status: "pending",
      };
    case "":
      return {
        ...state,
        status: "success",
        carDetails: action.payload,
      };
    case "":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state;
  }
};
