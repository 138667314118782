import React from 'react'
import Content from '../components/Content/Content'

const Store = () => {
  return (
    <Content>

    <div>Store</div>
    </Content>
  )
}

export default Store 