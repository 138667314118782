import { combineReducers } from "redux";
import { getCarByIdReducer } from "./Filters/getCarByIdReducer";
import { getCarsByFilterReducer } from "./Filters/getCarsByFilterReducer";
import { getFilterInfoReducer } from "./Filters/getFilterInfoReducer";
import { getLocationReducer } from "./Filters/getLocationReducer";
import { loginReducer } from "./Login/loginReducer";
import { logoutReducer } from "./Login/logoutReducer";
import { menuReducer } from "./Menu/menuReducer";
import { getProfileReducer } from "./Profile/getProfileReducer";
import { updateProfileReducer } from "./Profile/updateProfileReducer";
import { getReserveListReducer } from "./Reserve/getReserveListReducer";
import { loginReserveReducer } from "./Reserve/loginReserveReducer";
import { reserveCarReducer } from "./Reserve/reserveCarReducer";
import { signUpReducer } from "./SignUp/singUpReducer";
import { getTopTenReducer } from "./TopTen/getTopTenReducer";
import { getBalanceReducer } from "./Wallet/getBalanceReducer";
import { getPurchaseListReducer } from "./Wallet/getPurchaseListReducer";
import { sendTokenReducer } from "./FirebaseReducer/sendTokenReducer";
import {  sendBillNumberReducer } from "./Payment/sendBillNumberReducer";
import { redirectToEnReducer } from "./redirectToEn/redirectToEnReducer";

export const rootReducer = combineReducers({
  location: getLocationReducer,
  getFilterInfo : getFilterInfoReducer,
  getCarsByFilter: getCarsByFilterReducer,
  getCarById: getCarByIdReducer,
  login : loginReducer,
  signUp:signUpReducer,
  menu : menuReducer,
  logout : logoutReducer,
  getReserveList : getReserveListReducer,
  reserveCar : reserveCarReducer,
  loginReserve : loginReserveReducer,
  getBalance : getBalanceReducer,
  getPurchaseList : getPurchaseListReducer,
  getProfile : getProfileReducer,
  updateProfile : updateProfileReducer,
  getTopTen : getTopTenReducer,
  sendToken : sendTokenReducer,
  sendBillNumber : sendBillNumberReducer,
  redirectToEn : redirectToEnReducer,
  
});
