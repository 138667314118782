import React, { useRef } from "react";
import Wrapper from "../common/Wrapper";
import Span from "../common/Span";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";

const Content = ({ children }) => {
  const contentScroll = useRef(null);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  console.log("REEEEEF", contentScroll?.current?.overflowY);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [scrollable, setScrollable] = useState(false);

  useEffect(() => {
    const divElement = contentScroll.current;
    if (divElement.scrollHeight > divElement.clientHeight) {
      setScrollable(true);
    } else {
      setScrollable(false);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const container = contentScroll.current;
      if (container) {
        setIsAtTop(container.scrollTop === 0);
        setIsAtBottom(
          container.scrollHeight - container.scrollTop ===
            container.clientHeight
        );
      }
    };

    const container = contentScroll.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  console.log("BOTTTOM", isAtBottom);

  const handleScrollUp = () => {
    if (contentScroll.current) {
      contentScroll.current.scrollTop -=655; // Adjust scroll speed as needed
    }
  };

  const handleScrollDown = () => {
    if (contentScroll.current) {
      contentScroll.current.scrollTop +=655; // Adjust scroll speed as needed
    }
  };
  return (
    <Wrapper alignItem="flex-end" height="70vh" justifyContent="flex-start">
      <Wrapper width="5%"  justifyContent="flex-end">
        <Icon onClick={goBack} />
      </Wrapper>
      <Wrapper width="5%" direction="column" alignItem="flex-start" >
        {scrollable ? (
          <>
            <IconUp isAtTop={isAtTop} onClick={handleScrollUp} />
            <IconDown isAtBottom={isAtBottom} onClick={handleScrollDown} />
          </>
        ) : (
          <>
            <Icon />
            <Icon />
          </>
        )}
      </Wrapper>

      <div
        ref={contentScroll}
        style={{
          height: "100%",
          width: "80%",
          border: "1px solid ",
          overflowY: "scroll",
          padding: "0.55rem",
        }}
      >
        {children?.text ?<Span color="#a2a2a2">{children?.text}</Span>  : children}

      </div>
    </Wrapper>
  );
};

export default Content;

const IconUp = styled(RiCheckboxBlankFill)`
  font-size: 2rem;
  color: ${({ isAtTop }) => (isAtTop ? "#989898" : "#616161")};
  cursor: pointer;
`;
const IconDown = styled(RiCheckboxBlankFill)`
  font-size: 2rem;
  color: ${({ isAtBottom }) => (isAtBottom ? "#989898" : "#616161")};
  cursor: pointer;
`;

const Icon = styled(RiCheckboxBlankFill)`
  font-size: 2rem;
  color: #dfdfdf;
  cursor: pointer;
`;


