const initialState = {
    showMenu : false
}

export const menuReducer = (state =initialState, action) => {
    switch (action.type) {
        case "OPEN_MENU" :
            return{
                ...state,
                showMenu:!state.showMenu
            };

            case "CLOSE_MENU" : 
            return {
                ...state,
                showMenu : false
            }
           
                default :
                return state
    }
}