const initialState = {
  status: "idle",
  filterInfoList: [],
};

export const getFilterInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FILTER_INFO_LOADING":
      return {
        ...state,
        status: "pending",
      };
    case "GET_FILTER_INFO_DONE":
      return {
        ...state,
        status: "success",
        filterInfoList: action.payload,
      };
    case "GET_FILTER_INFO_FAILED":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state;
  }
};
