const initialState = {
  status: "idle",
  filteredList: [],
  filterInfo :{}
};

export const getCarsByFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CARS_BY_FILTER_LOADING":
      return {
        ...state,
        status: "pending",
      };
    case "GET_CARS_BY_FILTER_DONE":
      const { data, filterInfo } = action.payload
      return {
        ...state,
        status: "success",
        filteredList: data,
        filterInfo: filterInfo, 
      };
    case "GET_CARS_BY_FILTER_FAILED":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state;
  }
};
