import React from "react";
import Content from "../components/Content/Content";
import Wrapper from "../components/common/Wrapper";
import styled from "styled-components";
import one from "../assets/images/audii.png";
import two from "../assets/images/benz.jpg";
import five from "../assets/images/dodge.jpg";



import { RiCheckboxBlankFill } from "react-icons/ri";
import Span from "../components/common/Span";

const News = () => {
  return (
    <Content>
      <Wrapper direction="column" gap="2rem">
        <Wrapper justifyContent="flex-start" padding="0 0 2rem 0">
          <Icon />
          <Span size="1.5rem" weight="bold" color="#8d8d8d">04 </Span>
          <Span size="1.5rem" weight="bold" color="#8d8d8d">Art Gallery</Span>
        </Wrapper>

        <Wrapper>
          <Wrapper direction="column" >
            <Wrapper justifyContent="flex-start">
              <Icon /> 
              <Span color="#a2a2a2">

              Events
              </Span>
            </Wrapper>

            <Wrapper justifyContent="flex-start">
              <Span textAlign="end" color="#a2a2a2">
              Events ipsum dolor sit, amet consectetur adipisicing elit.
                Impedit debitis quisquam dolore. Non odit modi error molestiae
                ad perferendis maxime omnis consectetur maiores quis, velit,
                quidem itaque deleniti veritatis sit?
              </Span>
            </Wrapper>
          </Wrapper>

          <Wrapper>
            <Img src={one} />
          </Wrapper>
        </Wrapper>


        <Wrapper>
          <Wrapper direction="column">
            <Wrapper justifyContent="flex-start">
              <Icon /> 
              <Span color="#a2a2a2">

                Artists news
                </Span> 
            </Wrapper>

            <Wrapper justifyContent="flex-start">
              <Span textAlign="end" color="#a2a2a2">
              Artists ipsum dolor sit, amet consectetur adipisicing elit.
                Impedit debitis quisquam dolore. Non odit modi error molestiae
                ad perferendis maxime omnis consectetur maiores quis, velit,
                quidem itaque deleniti veritatis sit?
              </Span>
            </Wrapper>
          </Wrapper>

          <Wrapper>
            <Img src={two} />
          </Wrapper>
        </Wrapper>




        <Wrapper>
          <Wrapper direction="column">
            <Wrapper justifyContent="flex-start">
              <Icon /> 
              <Span color="#a2a2a2">

                Gallery news
                </Span> 
            </Wrapper>

            <Wrapper justifyContent="flex-start">
              <Span textAlign="end" color="#a2a2a2">
              Gallery ipsum dolor sit, amet consectetur adipisicing elit.
                Impedit debitis quisquam dolore. Non odit modi error molestiae
                ad perferendis maxime omnis consectetur maiores quis, velit,
                quidem itaque deleniti veritatis sit?
              </Span> 
            </Wrapper>
          </Wrapper>

          <Wrapper>
            <Img src={five} />
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Content>
  );
};

export default News;

const Img = styled.img`
  width: 15rem;
  height: 15rem;
  border-radius: 0.2rem;
`;
const Icon = styled(RiCheckboxBlankFill)`
  font-size: 1.5rem;
  color: #a2a2a2;
  cursor: pointer;
`;
