import React from "react";
import { GalleryCard } from "../components/GalleryCard/GalleryCard";
import one from "../assets/images/audii.png";
import two from "../assets/images/benz.jpg";
import three from "../assets/images/blackCar-.jpg";
import four from "../assets/images/bmv520.png";
import five from "../assets/images/dodge.jpg";
import Content from "../components/Content/Content";
import { useNavigate } from "react-router";
import Wrapper from "../components/common/Wrapper";

const Gallery = () => {
  const srcList = [
    { src: five, name: "numb_05", since: 2022, numb: 7, city: "BJ" },
    { src: one, name: "numb_01", since: 2022, numb: 3, city: "NY" },
    { src: two, name: "numb_02", since: 2022, numb: 6, city: "WDC" },
    { src: three, name: "numb_03", since: 2022, numb: 12, city: "TH" },
    { src: four, name: "numb_04", since: 2022, numb: 5, city: "MSH" },
  ];

  const navigate = useNavigate();
  const onClickHandler = (add) => {
    navigate(`/gallery/${add}`);
  };

  return (
    <Content>
      <div>
        {srcList.map((item) => (
          <Wrapper onClick={() => onClickHandler(item.name)} cursor="pointer">
            <GalleryCard src={item.src} />
          </Wrapper>
        ))}
      </div>
    </Content>
  );
};

export default Gallery;
