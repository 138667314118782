const initialState = {
  status: "idle",
  listArray: [],
};

export const getTopTenReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TOP_TEN_LOADING":
      return {
          ...state,
          status :"pending"
        };
    case "GET_TOP_TEN_DONE":
      return {
        ...state,
        status : "success",
        listArray : action.payload
      };
    case "GET_TOP_TEN_FAILED":
      return {
        ...state,
        status : "failed" 
      };
    default:
      return state;
  }
};
