import React from 'react'
import Content from '../components/Content/Content'

const Contact = () => {
  return (
    <Content>

    <div>Contact</div>
    </Content>
  )
}

export default Contact 